.donate-header-img {
    width: 100%;
    height: 36vw;

    background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/car_pics/sunrider/2021/car3.jpg), no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center 40%;

    display: flex;
    position: relative;
    align-items: center;
}

//Resize text for mobile
@media screen and (max-width: 425px) {
    .donate-header-img {
        height: 50vw;
    }
}