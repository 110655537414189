.team-header-img {
    width: 100%;
    height: 36vw;

    background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/people/team24/groupphoto.JPG), no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center 27%;

    display: flex;
    position: relative;
    align-items: center;
}

#officer-category-heading {
    padding-top: 60px;
}

.officer-col {
    max-width: 186px;
    justify-content: center;
    align-content: center;
    margin: 1vw;
    padding: 0;
}

//Image + Name + Title Container
.officer-container {
    position: relative;
    justify-content: center;
    width: 186px;
    height: 330px;
    padding: 0;
    margin: 0 0 40px;
}

//Image Container
.headshot {
    position: relative;
    width: 100%;
    height: 230px;
    left: 0;
    margin: 0;
    padding: 0;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, .35);
}

//Img in container
.headshot-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}

.dropdown-menu {
    background: rgba(1,1,1,0.5);
    position: absolute;
    width: 170px;
    left: 15px;


    justify-content: center;
    text-align: center;

    margin: 10px;
    padding: 0;
}

.dropdown-row {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    margin: .5rem 0;
    padding:0;
}

.dropdown-row:hover {
    background-color: var(--color-dark-grey);
}

.comp_team_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 0;

    p {
        color: var(--color-white);
        margin: 0 .1rem 0 0;
    }

    #comp_names {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 80vw;
        margin: 1rem;
        padding: 0;
    }

    .comp_name_col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        width: 12rem;
    }
}

/* Resize for fit - scale down 25% */
@media screen and (max-width: 1216px) {
    .officer-col {
        max-width: 150px;
    }
    .officer-container {
        width: 150px;
        height: 285px;
    }

    .headshot {
        width: 100%;
        height: 185px;
    }
}

// Resize for large tablet
@media screen and (max-width: 786px) {

}

//Resize text for mobile
@media screen and (max-width: 425px) {
    .officer-col {
        margin-left: 3vw;
        margin-right: 3vw;
    }
    .team-header-img {
        height: 50vw;
    }
}