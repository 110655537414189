.home-header-img {
    width: 100%;
    height: 36vw;
    background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/car_pics/sunrider/2022/car3_fl_gators.JPG), no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center 33%;

    display: flex;
    position: relative;
    align-items: center;
}

/* Sections of Page (container) */
.section {
    position: relative;
    justify-content: center;
    align-content: center;

    width: 100%;
    height: auto;

    margin: 0;
    padding: 7rem 8vw 6rem;
}

.fsgp-row {
    justify-content: center;
    img {
        display: flex;
        flex-direction: row;
        width: 85vw;
        margin: 3vw 0 0;
    }
}

.join-team {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .join-text-half {
        flex-direction: column;
        width: 35%;
        max-width: 35%;
        margin-right: 3.5rem;
    }

    #schedule {
        width: 28rem;
        //height: 12rem;
        background: #D8E9FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
        margin-left: 3.5rem;
        box-shadow: var(--color-grey) 7px 7px 7px ;

        #schedule-dates {
            margin: 0.5rem 0 0;
        }

        h4 {
            color: #0067C7;
        }

        p {
            margin-bottom: 0.5rem;
        }
    }
}

.accent_link {
    font-weight: 500;
    color: var(--color-bright_blue)
}

.flex-cols-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-rows-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-rows-center p {
    margin: 0 1rem;
}

#link:hover {
    text-decoration: none;
}

#home-sponsors-logo-col {
    max-width: 18vw;
    margin: 2vw 0;

    .sponsor-logo {
        max-width: 18vw;
        padding: 0 2vw;
    }
}

#sponsor_logos_half {
    padding: 0;
    display: inline;
}

#sponsor_blurb_half {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*  Officer Carousel Animation
    source: https://codepen.io/studiojvla/pen/qVbQqW
*/
@mixin blue-gradient {
    background: linear-gradient(to right,  var(--color-dark_blue) 0%, transparent 100%);
}
$animationSpeed: 50s;

// Animation
@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 7))}
}

// Officer Slide Styling
.slider {
    background: var(--color-dark_blue);
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 90%;

    &::before,
    &::after {
        @include blue-gradient;
        content: "";
        height: 100%;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slide {
        height: 100px;
        width: 250px;
    }

    //Container
    .headshot {
        position: relative;
        min-width: 225px;
        width: 100%;
        height: 300px;
        left: 65px;
        margin: 0 32px;
        box-shadow: 5px 20px 20px 5px rgba(0, 0, 0, .35);
     }

    //Image in container
    .headshot-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
    }
}

@media screen and (max-width: 1220px) {
    .section {
        padding: 8vw 8vw 6vw 8vw;
    }
}

/* Resize for large tablet - scale down 25% */
@media screen and (max-width: 768px) {
    .slider {
        .headshot {
            width: 160px;
            height: 240px;
        }
    }
}

//Resize text for mobile
@media screen and (max-width: 550px) {
    .home-header-img {
        height: 50vw;
    }

    .section {
        padding: 8vw 3vw 7vw;
    }

    .fsgp-row {
        margin: 0 4vw;
        img {
            margin-top: 7vw;
        }
    }

    .slider {
        margin-bottom: 10px;
        &::before,
        &::after {
            width: 110px;
        }
        .headshot {
            width: 100px;
            height: 150px;
            margin-right: 32px;
        }
    }

    .join-team {
        margin: 0 5vw;
        flex-direction: column-reverse;

        .join-text-half {
            width: 100%;
            max-width: 100%;
            margin: 0;
        }

        #schedule {
            width: 85vw;
            height: max-content;
            padding: 0 0 2rem;
            margin: 0 0 2.5rem;

            h4 {
                padding: 2rem 0 1rem;
            }
        }

        p, a {
            margin-bottom: 2rem;
        }
    }

    .flex-rows-center p {
        margin: 0;
        padding: 0 1rem;
    }

    #home-sponsors-logo-col {
        max-width: none;
        width: 60vw;
        margin: 0 20vw 2rem;
        padding: 0;

        .sponsor-logo {
            max-width: none;
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }
}
