//DEFINES BUTTON STYLES
$margin-top: 2rem;
$margin-bottom: 0;
$curve: 40px;

$button-height: 50px;
$small-button-height: 35px;
$square-button-height-width: 40px;

.btn-primary {
  font-family: 'Inter';
  font-weight: 525;
  font-size: 18px;
  width: 180px;
  height: $button-height;

  left: calc(50% - 180px/2 + 2px);
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-bright_blue);
  background-color: transparent;
  border: 3px solid var(--color-bright_blue);
  border-radius: $curve;

  display: flex;
  position: relative;
  justify-content: center;
  top: 0;
}

.btn-primary:hover {
  color: var(--color-white);
  background-color: var(--color-bright_blue);
  border: 3px solid var(--color-bright_blue);
}

.btn-secondary {
  font-family: 'Inter';
  font-weight: 525;
  font-size: 18px;
  width: 180px;
  height: $button-height;

  left: calc(50% - 180px/2 + 2px);
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-grey);
  background-color: transparent;
  border: 3px solid var(--color-grey);
  border-radius: $curve;

  display: flex;
  position: relative;
  justify-content: center;
  top: 0px;
}

.btn-secondary:hover {
  color: var(--color-white);
  background-color: var(--color-grey);
  border: 3px solid var(--color-grey);
}

.btn-tertiary {
  font-family: 'Inter';
  font-weight: 525;
  font-size: 18px;
  width: 180px;
  height: $button-height;

  left: calc(50% - 180px/2 + 2px);
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-primary_orange);
  background-color: transparent;
  border: 3px solid var(--color-primary_orange);
  border-radius: $curve;

  display: flex;
  position: relative;
  justify-content: center;
  top: 0px;
}

.btn-tertiary:hover {
  color: var(--color-white);
  background-color: var(--color-primary_orange);
  border: 3px solid var(--color-primary_orange);
}

.btn-transparent {
  width: 180px;
  height: $small-button-height;

  left: calc(50% - 180px/2 + 2px);
  margin: 0;

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-primary_orange);
  background-color: transparent;
  border: transparent;
  border-radius: $curve;
  transition: 5ms;

  display: flex;
  position: relative;
  justify-content: center;
  top: 0;
}

.btn-transparent h5 {
  padding: 0;
  margin: 0;
}

.btn-transparent:hover {
  color: var(--color-bright_blue);
  background-color: transparent;
  border: transparent;
  transition: 5ms;
}

.btn-transparent:active {
  color: var(--color-bright_blue);
  background-color: transparent;
  border: transparent;
  transition: 5ms;

}

.btn-social-media-icon {
  width: 40px;
  height: 40px;

  left: 0;
  position: relative;
  display: flex;
  margin: 0 1vw;

  background: transparent;
  border: transparent;
}

.btn-social-media-icon:hover {
  background: transparent;
  border: transparent;
}

.btn-collapsable-field {
  width: $square-button-height-width;
  height: $square-button-height-width;

  margin: 0  1vw 0 0;
  left: 0;
  right: 0;
  top: 0;

  color: var(--color-bright_blue);
  background-color: transparent;
  border: 3px solid var(--color-bright_blue);
  border-radius: 10px;
}

#nav-btn {
  margin: 0;
}

#large-btn {
  width: 250px;
  left: calc(50% - 250px/2 + 2px);
}

/* Resize for large tablet - scale down 25% */
@media screen and (max-width: 768px) {
  .btn-primary, #small-btn {
    font-size: 14px;
    width: 144px;
    height: 50px;
    left: calc(50% - 144px/2 + 2px);
  }

  .btn-social-media-icon {
    width: $square-button-height-width;
    height: $square-button-height-width;
    left: 0;
  }
}

/* Resize for mobile */
@media screen and (max-width: 425px) {
  .btn-primary {
    margin-top: 8vw;
    margin-bottom: 3vw;
  }
  .btn-social-media-icon {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  .btn-collapsable-field {
    width: $square-button-height-width;
    height: $square-button-height-width;
    margin: 0 4vw;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  #nav-btn {
    margin: 1rem 0 2rem;
  }
}