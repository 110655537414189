@import "~bootstrap/scss/bootstrap";
@import "./content/styles/Home.scss";
@import "./content/styles/Footer.scss";
@import "./content/styles/About.scss";
@import "./content/styles/Sponsors.scss";
@import "./content/styles/Team.scss";
@import "./content/styles/Donate.scss";
@import "./content/styles/Button.scss";
@import "./content/styles/Cars.scss";

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  scroll-behavior: smooth;
}

.no-margin-padding {
  margin: 0;
  padding: 0;
}

body, p, a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: left;
  color: var(--color-black);
  transition: 5ms ease;

  padding: 0;
  margin: 0 0 1rem 0;
}

/* Title */
.title-text {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-size: 64px;
  opacity: 0.9;

  color: var(--color-white);
  text-shadow: -6px 6px 6px rgba(0, 32, 62, 0.20);
  transition: 5ms;

  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  line-height: 68px;
  text-transform: capitalize;

  //Position
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  padding-top: 0;
}

/* Heading 1 */
h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-bright_blue);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0px;
  padding-top: 0;
  padding-bottom: 60px;
}

/* Heading 2 */
h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-primary_orange);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0;
  padding-top: 0;
  padding-bottom: 60px;
}

/* Heading 3 */
h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-bright_blue);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0px;
  padding-top: 150px;
  padding-bottom: 0;
}

/* Heading 4 */
h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-black);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0;
  margin: 0;
  padding: 3rem 0 1.5rem;
}

/* Heading 5 */
h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-white);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0;
  margin: 0;
  padding: 25px 0 15px;
}

/* Heading 6 */
h6 {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-grey);
  transition: 5ms;

  position: relative;
  justify-content: center;
  width: 100%;
  top: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}

.body-text-white {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: left;
  color: var(--color-white);
  transition: 5ms ease;

  padding: 0;
  margin: 0;
}

#nav-logo {
  width: 125px;
  height: 35px;
  margin: 0 1rem 0 0;
  padding: 0;
}

#nav-logo img {
  width: 100%;
}

//Resize text for large tablets: scale font down 25%
@media screen and (max-width: 768px) {
  .title-text {
    font-size: 52px;
  }
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 28px;
    padding-bottom: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
  body, p {
    font-size: 12px;
  }
  .body-text-white {
    font-size: 12px;
  }
}

//Resize text for small tablets & mobile: scale font down 50%
@media screen and (max-width: 570px) {
  .title-text {
    font-size: 42px;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
    padding-bottom: 24px;
  }
  h3 {
    font-size: 20px;
    padding-top: 75px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 10px;
  }
  body, p, a {
    font-size: 12px;
  }
  .body-text-white {
    font-size: 12px;
  }
}

//Resize header & text for mobile
@media screen and (max-width: 425px) {
  .title-text {
    font-size: 30px;
    line-height: 40px;
    padding: 0;
    margin: 0;
  }
  body, p, a {
    line-height: 18px;
  }
  .body-text-white {
    line-height: 18px;
  }
}