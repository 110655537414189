/*
Pulled from: https://bbbootstrap.com/snippets/social-media-icons-footer-54412302
*/

#footer {
    background: #00203E;
    padding-top: 8vh;
    padding-bottom: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    position: relative;
    justify-content: center;
    align-content: flex-end;
}

#sg-logo {
    width: 120px;
    height: auto;
    align-self: flex-end;
}

.column-container {
    width: 30%;
    height: auto;

    display: flex;
    position: relative;

    padding: 0;
    margin: 0;
}

.row-container {
    height: auto;
    width: 100%;

    position: relative;
    display: flex;

    justify-content: center;
    align-content: center;

    padding: 0;
    margin: 0;
}

#address {
    display: flex;
    align-items: end;
    text-align: right;

    margin-bottom: 10px;
}

.app-icon {
    height: 30px;
    width: 30px;

    position: relative;
    display: flex;
    justify-items: center;
    justify-content: center;

    margin: 0;
    //padding: 0;
}

/* Resize for larger tablet devices
@media screen and (max-width: 768px) {

}*/

/* Resize for mobile devices */
@media screen and (max-width: 425px) {
    #footer {
        padding-top: 8vw;
        padding-bottom: 8vw;
    }
    .row-container {
        justify-content: left;
    }
    .column-container {
        width: auto;
        justify-content: center;
        align-content: center;
        display: block;
    }
}
