@import "About";

.cars-header-img {
  width: 100%;
  height: 36vw;
  background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/car_pics/sunrider/2022/car3_zoom.jpg), no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center 60%;

  display: flex;
  position: relative;
  align-items: center;
}

#Torch, #Cielo, #Sunrider {

}

@media screen and (max-width: 425px) {
  .cars-header-img {
    height: 50vw;
  }
}