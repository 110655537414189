:root {
  /* Define global variables */
  --color-dark_blue: #00203E;
  --color-primary_blue: #00519C;
  --color-primary_orange: #F37833;
  --color-bright_blue: #0067c7;
  --color-white: #F8F8F8;
  --color-light-grey: #cdcdcd;
  --color-grey: #B6BABE;
  --color-medium-grey: #50555a;
  --color-black: #000000;
}

.horizontal-line {
  width: 80%;
  height: 1px;
  background: var(--color-light-grey);
  margin: 0 10%;
}

.nav-link {
  margin: 25px 10px 20px;
  padding: 0;
  font-size: 1rem;
}

/*Resize header & text for mobile*/
@media screen and (max-width: 425px) {
  .nav-link {
    padding: 0;
    margin: .5rem;
    line-height: 18px;
    font-size: 1rem;
  }
}

/*Define normal Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: normal;
  font-style: normal;
  font-variation-settings: normal;
}

/*Define medium Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: 500;
  font-style: normal;
  font-variation-settings: normal;
}

/*Define semi-bold Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: 600;
  font-style: normal;
  font-variation-settings: normal;
}

/*Define bold Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: bold;
  font-style: normal;
  font-variation-settings: normal;
}

/*Define light italic Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: 300;
  font-style: italic;
  font-variation-settings: normal;
}

/*Define bold italic Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: bold;
  font-style: italic;
  font-variation-settings: normal;
}

/*Define extra bold italic Inter font*/
@font-face {
  font-family: 'Inter';
  src: local("Inter-Variable"), url("./content/assets/fonts/Inter-VariableFont.woff2") format("woff2-variations");
  font-weight: bold;
  font-style: italic;
  font-variation-settings: normal;
}

/*Cards for Carousel to showcase awards*/
.award-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.award-card-image{
  width: 100%;
  height: 30rem;
  object-fit: contain;
}

.award-card-content{
  padding: 1% 5%;
}

.carousel-item-container{
  display: inline-flex;
  float: left;
  width: 100%;
}

.carousel-indicators li{
  background-color: #00203E;
}

.carousel-control-prev-icon,
.carousel-control-next-icon{
  background-color: #000000;
  border-radius: 45%;
  width: 30em;
  height: 15em;
}

