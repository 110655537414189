.contact-header-img {
  width: 100%;
  height: 36vw;
  background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/people/team22/team0.JPG), no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center 90%;

  display: flex;
  position: relative;
  align-items: center;
}

.transparent-box{
  width: auto;
  height: auto;

  background: transparent;
  border: transparent;

  align-content: center;
  justify-content: center;

  margin: 10vw;
}

.contact-entry-box{
  width: 100%;
  height: auto;

  background: rgba(0, 0, 0, .03);
  border: transparent 1px solid;
  border-radius: 0px;

  //box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, .125);
}

#contact-name {
  background: rgba(0, 0, 0, .04);
  border: transparent 1px solid;
  border-radius: 0px;
}

#contact-email {
  background: rgba(0, 0, 0, .04);
  border: transparent 1px solid;
  border-radius: 0px;
}

#contact-msg {
  background: rgba(0, 0, 0, .04);
  border: transparent 1px solid;
  border-radius: 0px;
}

//Resize text for mobile
@media screen and (max-width: 425px) {
  .contact-header-img {
    height: 50vw;
  }
  #contact-name {
    font-size: 12px;
  }
  #contact-email {
    font-size: 12px;
  }
  #contact-msg {
    font-size: 12px;
  }
}
