.sponsor-header-img {
    width: 100%;
    height: 36vw;

    background: linear-gradient(0deg, rgba(0, 32, 62, 0.25), rgba(0, 32, 62, 0.25)), url(../assets/images/people/team22/sponsorship0.jpeg), no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center 65%;

    display: flex;
    position: relative;
    align-items: center;
}

#sponsor-dropdown-h5 {
    color: #000000;
}

#sponsor-row {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: normal;
    gap: 2vw;
    margin: 2rem;
}

.sponsor-logo{
    max-width: 18vw;
    width: auto;
    height: auto;
    padding: 0;
    justify-self: center;
}

#platinum {
    margin: 0 2vw;
    max-width: 18vw;
    max-height: 7.5rem;
}

#gold {
    margin: 0 1vw;
    max-width: 12vw;
    max-height: 6rem;
}

#silver {
    max-width: 10vw;
    max-height: 5rem;
}

#bronze {
    max-width: 8vw;
    max-height: 4.5rem;
}

#valued {
    max-width: 6vw;
    max-height: 4rem;
}

#patron {
    width: auto;
    color: var(--color-medium-grey);
}

#sponsor-h4 {
    color: var(--color-primary_orange);
}

// Resize for large tablet
@media screen and (max-width: 786px){
    .sponsor-logo{
        width: 30vw;
    }
}

//Resize text for mobile
@media screen and (max-width: 425px) {
    .sponsor-header-img {
        height: 50vw;
    }
    .sponsor-logo {
        max-width: 25vw;
        width: auto;
        height: auto;
    }

    #platinum {
        margin: 0;
        max-width: 25vw;
        max-height: 4rem;
    }

    #gold {
        max-width: 20vw;
        max-height: 3.5rem;
    }

    #silver {
        max-width: 16vw;
        max-height: 3rem;
    }

    #bronze {
        max-width: 14vw;
        max-height: 3rem;
    }

    #valued {
        max-width: 12vw;
        max-height: 4.5rem;
    }
}