
/* Container for Car Images */
.car-cards {
  justify-content: center;
  margin: 0;
}

/* Container for Individual Car Image */
.car-card {
  position: relative;
  width: 26%;
  height: 300px;
  margin: 0 3%;
  transition: 0.5s ease;

  justify-content: center;
  display: flex;
  background-color: transparent;
  border: 0;

  overflow: hidden;
}

/* Car Image */
.car-card-img {
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.5s ease;
  object-fit: cover;
  background-position: center center;
}

.overlay {
  position: absolute;
  transition: 0.5s ease;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark_blue);
}

//Car Name
.overlay-text-header {
  color: var(--color-white);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40%;
  top: 20%;
  justify-content: center;
}

//Car Years
.overlay-text-body {
  color: var(--color-white);
  font-size: 18px;
  font-style: italic;
  text-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50%;
  margin: 0;
  justify-content: center;
}

//when hovering over car card, adjusts overlay opacity
.car-card:hover .overlay{
  opacity: .75;
}

.car-card:hover {
  box-shadow: 0 20px 20px 5px rgba(0, 0, 0, .25);
  transition: 0.5s ease;
  transform: translate(0, -1rem);
}

/* Resize for large tablet - scale down 25% */
@media screen and (max-width: 768px) {
  .car-card {
    height: 200px;
  }
}

//Resize text for mobile
@media screen and (max-width: 425px) {
  .car-card {
    width: 100%;
    height: 35vw;
    margin-bottom: 40px;
  }

  .overlay {
    opacity: .75;
    padding-top: 15px;
  }

  .overlay-text-body {
    margin-top: 10px;
  }
}